import React, { Suspense } from 'react';
import { PreloadedQuery } from 'react-relay';
import { KeyType } from 'react-relay/relay-hooks/helpers';
import { ConcreteRequest, OperationType, ReaderFragment } from 'relay-runtime';

import {
  Box,
  LoadingPlaceholder,
  Paginator as PicnicPaginator,
  Table,
  compositeComponent,
} from '@attentive/picnic';

import { createPaginationContext } from '../../hooks/pagination-context';

type CreateConnectedTableArgs = {
  rootQuery: ConcreteRequest;
  refetchableFragment: ReaderFragment;
};

const FallbackPaginator = () => {
  return (
    <PicnicPaginator.ButtonGroup
      hasNext={false}
      hasPrevious={false}
      loadNext={() => {}}
      loadPrevious={() => {}}
    />
  );
};

const FallbackRow = () => {
  return (
    <Table.BodyRow>
      <Table.BodyCell css={{ gridColumn: '1/-1' }}>
        <LoadingPlaceholder css={{ height: '$size5', width: '100%' }} />
      </Table.BodyCell>
    </Table.BodyRow>
  );
};

const FallbackBody = ({ numOfRows }: { numOfRows: number }) => {
  return (
    <Table.Body>
      {Array.from({ length: numOfRows }, (_, n) => n).map((idx) => (
        <FallbackRow key={idx} />
      ))}
    </Table.Body>
  );
};

// The entire table is wrapped in a function purely for typing reasons. In
// order to provide the consumer with typing for their `data` returned from gql,
// without asking them to pass us typing info into multiple components, we need
// to create a closure with the typing info at the top-level. This way the
// consumer can provide us their gql typing at a single place and we can
// generate components that are aware of those types. Otherwise there isn't a
// way for TS to infer a component's typing via implicit context and
// parent/child hierarchy.
function createConnectedTable<
  ConcreteQueryType extends OperationType,
  RefetchableQueryType extends OperationType,
  FragmentType extends KeyType
>({ rootQuery, refetchableFragment }: CreateConnectedTableArgs) {
  const [PaginationContextProvider, usePaginationData, usePaginationMetadata] =
    createPaginationContext<ConcreteQueryType, RefetchableQueryType, FragmentType>({
      rootQuery,
      refetchableFragment,
    });

  const ConnectedPaginator = () => {
    const {
      isLoadingNext,
      isLoadingPrevious,
      hasNext,
      hasPrevious,
      loadNext,
      loadPrevious,
      totalCount,
    } = usePaginationData({ windowData: true });
    const { pageSize, pageIndex } = usePaginationMetadata();

    if (isLoadingNext || isLoadingPrevious) {
      return <FallbackPaginator />;
    }

    return (
      <>
        {totalCount > 0 && (
          <PicnicPaginator.Label
            pageIndex={pageIndex}
            itemsPerPage={pageSize}
            totalItems={totalCount}
            css={{ alignSelf: 'center', marginRight: '$space4' }}
          />
        )}
        <PicnicPaginator.ButtonGroup
          hasNext={hasNext}
          hasPrevious={hasPrevious}
          loadNext={loadNext}
          loadPrevious={loadPrevious}
        />
      </>
    );
  };

  const Paginator = () => {
    return (
      <Suspense fallback={<FallbackPaginator />}>
        <ConnectedPaginator />
      </Suspense>
    );
  };

  type BodyProps = {
    children: (data: ReturnType<typeof usePaginationData>['data']) => React.ReactNode;
  };
  const ConnectedBody = ({ children }: BodyProps) => {
    const { pageSize } = usePaginationMetadata();
    const { data, isLoadingNext, isLoadingPrevious } = usePaginationData({ windowData: true });

    if (isLoadingNext || isLoadingPrevious) {
      return <FallbackBody numOfRows={pageSize} />;
    }

    return <Table.Body>{children(data)}</Table.Body>;
  };
  const Body = ({ children }: BodyProps) => {
    const { pageSize } = usePaginationMetadata();

    return (
      <Suspense fallback={<FallbackBody numOfRows={pageSize} />}>
        <ConnectedBody>{children}</ConnectedBody>
      </Suspense>
    );
  };

  type ConnectedTableProps = {
    queryRef: PreloadedQuery<ConcreteQueryType>;
  } & React.ComponentProps<typeof Table>;
  const ConnectedTable = ({ queryRef, children, ...tableProps }: ConnectedTableProps) => {
    return (
      <PaginationContextProvider queryRef={queryRef}>
        <Table {...tableProps}>{children}</Table>
        <Box css={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '$space3' }}>
          <Paginator />
        </Box>
      </PaginationContextProvider>
    );
  };

  const Component = compositeComponent(ConnectedTable, {
    Header: Table.Header,
    HeaderRow: Table.HeaderRow,
    HeaderCell: Table.HeaderCell,
    SortableHeaderCell: Table.SortableHeaderCell,
    Body,
    BodyRow: Table.BodyRow,
    BodyFocusableRow: Table.BodyFocusableRow,
    BodyCell: Table.BodyCell,
    FocusWrapper: Table.FocusWrapper,
  });

  return [Component, usePaginationData] as const;
}

export { createConnectedTable };
