import { ReaderFragment } from 'relay-runtime';

// Modified version of:
// https://github.com/facebook/relay/blob/cef4b33d5ebd0fbc3b64687a653aa70c619c43c3/packages/relay-runtime/util/getRefetchMetadata.js#L19
function getRefetchMetadata(fragmentNode: ReaderFragment) {
  const refetchMetadata = fragmentNode.metadata?.refetch;
  const refetchableRequest = refetchMetadata?.operation;
  const fragmentRefPathInResponse = refetchMetadata?.fragmentPathInResult;
  const identifierField = refetchMetadata?.identifierField;

  return {
    fragmentRefPathInResponse,
    identifierField,
    refetchableRequest,
    refetchMetadata,
  };
}

// Modified verion of:
// https://github.com/facebook/relay/blob/cef4b33d5ebd0fbc3b64687a653aa70c619c43c3/packages/relay-runtime/util/getPaginationMetadata.js#L20
function getPaginationMetadata(fragmentNode: ReaderFragment) {
  const { refetchableRequest: paginationRequest, refetchMetadata } =
    getRefetchMetadata(fragmentNode);

  const paginationMetadata = refetchMetadata?.connection;
  const connectionPathInFragmentData = paginationMetadata?.path;
  const identifierField = refetchMetadata?.identifierField;

  return {
    connectionPathInFragmentData,
    identifierField,
    paginationRequest,
    paginationMetadata,
  };
}

export { getPaginationMetadata };
