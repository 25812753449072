import { cloneDeep } from '@attentive/nodash';

import { RelayConnection, RelayConnectionFields } from '../../utils/relay';

function getWindowedConnection(
  connection: RelayConnection | null,
  cursors: Array<string | null>,
  pageIndex: number
) {
  if (connection === null) {
    return null;
  }

  const { EDGES } = RelayConnectionFields;
  const clonedConnection = cloneDeep(connection);
  const edges = clonedConnection[EDGES] || [];
  const start = edges.findIndex((edge) => {
    return edge.cursor === cursors[pageIndex];
  });

  let end = edges.findIndex((edge) => {
    return edge.cursor === cursors[pageIndex + 1];
  });
  if (end === -1) {
    end = edges.length;
  }

  // This feels like some funky logic, but it's because of how we collect
  // cursors from relay. The cursor of a page tracks the END cursor of the
  // previous page. This means a page is exclusive of the previous page's
  // cursor and inclusive of the next page's. This is the opposite of how
  // `slice()` works, so everything needs to shift up one.
  clonedConnection[EDGES] = edges.slice(start + 1, end + 1);

  return clonedConnection;
}

export { getWindowedConnection };
