import * as PopoverPrimitive from '@radix-ui/react-popover';
import { useCombobox } from 'downshift';
import React, { createContext, useContext, useState, useRef, Suspense } from 'react';
import { PreloadedQuery, usePreloadedQuery, usePaginationFragment } from 'react-relay';
import { KeyType, KeyTypeData } from 'react-relay/relay-hooks/helpers';
import { ConcreteRequest, OperationType, ReaderFragment } from 'relay-runtime';

import {
  Text,
  List,
  Box,
  LoadingIndicator,
  ContinuousScroll,
  InputGroup,
  Button,
  Icon,
  TextInput,
  SearchableSelect as PicnicSearchableSelect,
  getItemPropsType,
  MenuItem,
  MenuItems,
  MenuItemValueType,
  SelectProps,
  ArrowWrapper,
  Menu as PicnicSelectMenu,
  useItems,
  SelectItemsList,
  compositeComponent,
  useRecursiveMatch,
  styled,
} from '@attentive/picnic';

import { getPaginationMetadata } from '../../utils/get-pagination-metadata';
import { MAX_Z_INDEX_VALUE } from '../../utils/z-index';

const PU_MENU_MIN_HEIGHT = 200;
const PU_MENU_CONTENT_OFFSET = 8;
const PU_MENU_MAX_HEIGHT = 400;
const DEFAULT_PAGE_SIZE = 20;

const StyledPopoverContent = styled(PopoverPrimitive.Content, {
  zIndex: MAX_Z_INDEX_VALUE,
});

const itemToString = (item: MenuItem | null) => item?.label ?? '';

type ItemsLookup = Record<MenuItemValueType, { item: MenuItem; index: number }>;

type CreateConnectedSelectArgs = {
  rootQuery: ConcreteRequest;
  refetchableFragment: ReaderFragment;
};

// The entire select is wrapped in a function purely for typing reasons. In
// order to provide the consumer with typing for their `data` returned from gql,
// without asking them to pass us typing info into multiple components, we need
// to create a closure with the typing info at the top-level. This way the
// consumer can provide us their gql typing at a single place and we can
// generate components that are aware of those types. Otherwise there isn't a
// way for TS to infer a component's typing via implicit context and
// parent/child hierarchy.
function createConnectedSelect<
  ConcreteQueryType extends OperationType,
  RefetchableQueryType extends OperationType,
  FragmentType extends KeyType
>({ rootQuery, refetchableFragment }: CreateConnectedSelectArgs) {
  const QueryRefContext = createContext<PreloadedQuery<ConcreteQueryType> | null>(null);
  const useQueryRef = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return useContext(QueryRefContext)!;
  };

  const ComboboxHelpersContext = createContext<{
    highlightedIndex: number;
    selectedItem: MenuItem | null;
    getItemProps: getItemPropsType;
    itemsLookup: ItemsLookup;
    size: 'medium' | 'small';
    onItemsChange: (items: MenuItem[] | null) => void;
  } | null>(null);
  const useComboboxHelpers = () => {
    return useContext(ComboboxHelpersContext);
  };

  function usePaginationData() {
    const queryRef = useQueryRef();
    const queryData = usePreloadedQuery<ConcreteQueryType>(rootQuery, queryRef) as FragmentType;

    const { paginationMetadata } = getPaginationMetadata(refetchableFragment);
    const pageSize: number = paginationMetadata?.forward
      ? queryRef.variables[paginationMetadata?.forward?.count]
      : DEFAULT_PAGE_SIZE;

    const paginationControls = usePaginationFragment<RefetchableQueryType, FragmentType>(
      refetchableFragment,
      queryData
    );

    return {
      ...paginationControls,
      loadNext: () => paginationControls.loadNext(pageSize || DEFAULT_PAGE_SIZE),
    };
  }

  // The select component shows the currently selected value based on the items
  // that have been loaded into the item list. Because the item list is controlled
  // by a render prop in `ItemList`, we need to render `ItemList` to build this
  // list and register the items with the top-level select component. The issue
  // is that with radix's popover, it removes the menu until it's opened rather
  // than just visually hide it. This means we don't build the item list until
  // the user opens the menu. Under most circumstances this is fine, but it
  // causes issues on the component's initial load if it's provided a value.
  // The user won't see this initial value selection until they open the menu and
  // trigger the item list generation. In order to get around this we can render
  // the menu component at the top level, but visually hide it from the user.
  function SpecialHiddenMenuToTriggerInitialDataLoad({ menu }: { menu: React.ReactNode }) {
    return <Box css={{ display: 'none' }}>{menu}</Box>;
  }

  type DataLoadingSuspenderProps = {
    children: React.ReactNode;
  };
  function DataLoadingSuspender({ children }: DataLoadingSuspenderProps) {
    // When data is being fetched this will suspend and trigger a wrapping
    // suspense boundary. Useful when trying to coordinate loading indicators
    // across the select.
    usePaginationData();

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  type ItemListProps = {
    children: (data: KeyTypeData<FragmentType>) => React.ReactNode;
  };
  function ItemList({ children }: ItemListProps) {
    const comboboxHelpers = useComboboxHelpers();
    const { data, loadNext, isLoadingNext, hasNext } = usePaginationData();
    const childItems = children(data);
    const items = useItems(childItems);

    React.useEffect(() => {
      if (data) {
        comboboxHelpers?.onItemsChange(items);
      } else {
        comboboxHelpers?.onItemsChange(null);
      }
      // `childItems`/`items` will change on every render, so we need to rely on
      // `data` as a stable representation of when items change. This in theory
      // could cause issues if the consumer changes their render function's
      // output irrelative to the data.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    if (items.length === 0 || !comboboxHelpers) {
      return (
        <Box css={{ p: '$space2' }}>
          <Text>No results</Text>
        </Box>
      );
    }

    return (
      <ContinuousScroll isLoading={isLoadingNext} hasMore={hasNext} onLoadMore={loadNext}>
        <List variant="unstyled">
          <SelectItemsList
            highlightedIndex={comboboxHelpers.highlightedIndex as number}
            selectedItem={comboboxHelpers.selectedItem as MenuItem}
            getItemProps={comboboxHelpers.getItemProps as getItemPropsType}
            itemsLookup={comboboxHelpers.itemsLookup as ItemsLookup}
            size={comboboxHelpers.size}
          >
            {childItems}
          </SelectItemsList>
        </List>
      </ContinuousScroll>
    );
  }

  type MenuProps = {
    children: React.ReactNode;
  };
  function Menu({ children }: MenuProps) {
    const { ItemListElement } = getChildElements(children);

    return (
      <Suspense
        fallback={
          <Box css={{ p: '$space2' }}>
            <Text>Loading...</Text>
          </Box>
        }
      >
        {ItemListElement}
      </Suspense>
    );
  }

  type ConnectedSelectProps = {
    queryRef: PreloadedQuery<ConcreteQueryType>;
  } & SelectProps;
  const ConnectedSelect = ({
    queryRef,
    placeholder = 'Search',
    css = {},
    value,
    onChange,
    onSearchTermChange,
    size = 'medium',
    state = 'normal',
    disabled = false,
    children,
    ...rest
  }: ConnectedSelectProps) => {
    const [term, setTerm] = useState('');
    const [items, setItems] = useState<MenuItems>([]);
    const inputGroupRef = useRef<HTMLDivElement>(null);
    // Necessary to cast because otherwise TS thinks the `value` arg is `never`.
    const handleChange = onChange as (value: MenuItemValueType) => void;

    const getItem = (itemValue?: MenuItemValueType) => {
      if (itemValue !== undefined && itemValue !== null) {
        return items.find((item) => item.value === itemValue && !item.disabled) ?? null;
      }

      return null;
    };

    const filteredItems = useRecursiveMatch(items, term);
    const itemsLookup = filteredItems.reduce(
      (prev: Record<MenuItemValueType, { item: MenuItem; index: number }>, curr, index) => {
        prev[curr.value] = {
          item: curr,
          index,
        };
        return prev;
      },
      {}
    );

    const handleSearchTermChange = (searchTerm: string) => {
      setTerm(searchTerm);
      onSearchTermChange?.(searchTerm);
    };

    const handleItemsChanged = (nextItems: MenuItems | null) => {
      setItems(nextItems || []);
    };

    const {
      isOpen,
      openMenu,
      getMenuProps,
      getInputProps,
      getComboboxProps,
      getToggleButtonProps,
      highlightedIndex,
      getItemProps,
      selectedItem,
    } = useCombobox({
      itemToString,
      inputValue: term,
      items: filteredItems,
      selectedItem: getItem(value),
      onSelectedItemChange: ({ selectedItem: nextItem }) => {
        if (!nextItem) {
          return;
        }

        handleSearchTermChange('');
        const matchedItem = items.find((item) => item.value === nextItem?.value);
        if (matchedItem) {
          handleChange(matchedItem.value);
        }
      },
    });

    const { MenuElement } = getChildElements(children);

    const availableHeightForMenu =
      typeof window === 'undefined'
        ? 0
        : window.innerHeight -
          // get the size of element to its relative position
          (inputGroupRef?.current?.getBoundingClientRect().bottom || 0) -
          //to match the offset at top from the select button
          PU_MENU_CONTENT_OFFSET * 2;

    return (
      <ComboboxHelpersContext.Provider
        value={{
          highlightedIndex,
          selectedItem,
          getItemProps,
          itemsLookup,
          size,
          onItemsChange: handleItemsChanged,
        }}
      >
        <QueryRefContext.Provider value={queryRef}>
          <PopoverPrimitive.Root open={isOpen}>
            <PopoverPrimitive.Trigger asChild>
              <InputGroup {...getComboboxProps({ ref: inputGroupRef })} css={{ width: '100%' }}>
                <TextInput
                  value={term}
                  placeholder={selectedItem ? selectedItem.label : placeholder}
                  data-testid="searchable-select-input"
                  {...getInputProps()}
                  onClick={openMenu}
                  onChange={(e) => {
                    handleSearchTermChange(e.currentTarget.value);
                  }}
                  size={size === 'medium' ? 'normal' : 'small'}
                  state={state}
                  css={{
                    width: '100%',
                    '&::placeholder': { color: selectedItem ? '$textDefault' : '$textSubdued' },
                    ...css,
                  }}
                  disabled={disabled}
                  {...rest}
                />
                <InputGroup.RightElement>
                  <Suspense fallback={<LoadingIndicator css={{ color: 'inherit' }} />}>
                    <DataLoadingSuspender>
                      <Button
                        variant="subdued"
                        disabled={disabled}
                        {...getToggleButtonProps()}
                        css={{ minHeight: 12, p: '$space2' }}
                      >
                        <ArrowWrapper variant={isOpen ? 'up' : 'down'}>
                          <Icon
                            css={{ display: 'block' }}
                            name="ChevronDown"
                            size="small"
                            description={isOpen ? 'Arrow pointing up' : 'Arrow pointing down'}
                          />
                        </ArrowWrapper>
                      </Button>
                    </DataLoadingSuspender>
                  </Suspense>
                </InputGroup.RightElement>
              </InputGroup>
            </PopoverPrimitive.Trigger>

            <PopoverPrimitive.Portal>
              <StyledPopoverContent
                sideOffset={PU_MENU_CONTENT_OFFSET}
                onOpenAutoFocus={(event) => {
                  event.preventDefault();
                }}
              >
                <PicnicSelectMenu
                  {...getMenuProps({}, { suppressRefError: true })}
                  css={{
                    position: 'relative',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    width: inputGroupRef?.current?.clientWidth,
                    // Restrict the height of the menu to the available space on
                    // the screen within a given min/max range.
                    maxHeight: Math.max(
                      PU_MENU_MIN_HEIGHT,
                      Math.min(availableHeightForMenu, PU_MENU_MAX_HEIGHT)
                    ),
                  }}
                >
                  {MenuElement}
                </PicnicSelectMenu>
              </StyledPopoverContent>
            </PopoverPrimitive.Portal>
          </PopoverPrimitive.Root>
          <SpecialHiddenMenuToTriggerInitialDataLoad menu={MenuElement} />
        </QueryRefContext.Provider>
      </ComboboxHelpersContext.Provider>
    );
  };

  function getChildElements(children: React.ReactNode) {
    const elements = {
      MenuElement: null as React.ReactNode,
      ItemListElement: null as React.ReactNode,
    };

    React.Children.forEach(children, (child) => {
      if (!React.isValidElement(child)) {
        return;
      }

      switch (child.type) {
        case Menu:
          elements.MenuElement = child;
          break;
        case ItemList:
          elements.ItemListElement = child;
          break;
      }
    });

    return elements;
  }

  return compositeComponent(ConnectedSelect, {
    Menu,
    ItemList,
    Item: PicnicSearchableSelect.Item,
    IconItem: PicnicSearchableSelect.IconItem,
  });
}

export { createConnectedSelect };
