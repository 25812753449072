import {
  mswGraphql,
  createConnection,
  createNodes,
  parseMSWHeaders,
  MSW_DELAY_HEADER,
} from '@attentive/mock-data';

const createCompanyData = (id: string | number) => ({
  id: `gql-${id}`,
  name: `Company ${id}`,
});

const COMPANY_LIST = Array.from({ length: 210 }, (_, n) => n + 1).map(createCompanyData);

export interface ConnectedMultiSelectQueryMSWHeaders {
  [MSW_DELAY_HEADER]?: number;
}

export const connectedMultiSelectQueryMock = mswGraphql.query(
  'ConnectedMultiSelect_Query',
  async (req, res, ctx) => {
    const mswHeaders = parseMSWHeaders<ConnectedMultiSelectQueryMSWHeaders>(req.headers);
    const { pageSize, filter, selectedCompanies } = req.variables;

    const selectedCompanyIds = selectedCompanies
      ? selectedCompanies.map((c: string) => c.split('-')[1])
      : null;
    const companyNodes = COMPANY_LIST.filter((c) =>
      c.name.toLowerCase().includes(filter.searchTerm.toLowerCase() || '')
    );

    const response = {
      companies: createConnection('Company', companyNodes, { limit: pageSize }),
      selectedCompanies: createNodes('Company', selectedCompanyIds.map(createCompanyData)),
    };

    return res(ctx.delay(Number(mswHeaders[MSW_DELAY_HEADER]) ?? 0), ctx.data(response));
  }
);

export const connectedMultiSelectRefetchableMock = mswGraphql.query(
  'ConnectedMultiSelect_Companies_refetchable',
  async (req, res, ctx) => {
    const mswHeaders = parseMSWHeaders<ConnectedMultiSelectQueryMSWHeaders>(req.headers);
    const { after, pageSize, filter, selectedCompanies } = req.variables;

    const selectedCompanyIds = selectedCompanies
      ? selectedCompanies.map((c: string) => c.split('-')[1])
      : null;
    const companyNodes = COMPANY_LIST.filter((c) =>
      c.name.toLowerCase().includes(filter.searchTerm.toLowerCase() || '')
    );
    const [, start] = after.split('-');
    const startPage =
      companyNodes.findIndex((c) => c.id === createCompanyData(start).id) / pageSize;

    const response = {
      companies: createConnection('Company', companyNodes, { page: startPage, limit: pageSize }),
      selectedCompanies: createNodes('Company', selectedCompanyIds.map(createCompanyData)),
    };

    return res(ctx.delay(Number(mswHeaders[MSW_DELAY_HEADER]) ?? 0), ctx.data(response));
  }
);
