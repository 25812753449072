import React, { createContext, useContext, useReducer, useEffect } from 'react';

/**
 * This reducer implements bi-directional pagination in FE state, and is
 * intended for use with data on a GraphQL connection which only supports
 * forward pagination. To do so we store the cursors for each query the FE
 * makes moving forwards, to allow us to then move backwards to previously
 * viewed pages. Since this is implemented entirely in FE state, this approach
 * DOES NOT support navigating initially to a certain page and then moving
 * bi-directionally.
 */

type PageCursors = [
  // The correct cursor value for the first page is actually the value "null".
  null,
  ...string[]
];

type PaginationControls = {
  pageCursors: PageCursors;
  pageIndex: number;
  // When we eventually support backwards pagination we'll need to add an
  // `isLoadingPrevious` field as well.
  isLoadingNext: boolean;
  // Until we support backwards pagination, we also need to manually keep track of whether
  // this reducer can still paginate forward since we can't rely on the relay pagination controls alone
  hasNext: boolean;
};

enum PaginationControlsActionType {
  LOAD_NEXT,
  LOAD_PREVIOUS,
  LOADED_NEXT,
  RESET,
}

type Action =
  | {
      type: PaginationControlsActionType.LOAD_NEXT;
      currentCursor: string;
    }
  | {
      type: PaginationControlsActionType.LOAD_PREVIOUS;
    }
  | {
      type: PaginationControlsActionType.LOADED_NEXT;
    }
  | { type: PaginationControlsActionType.RESET };

const initialState: PaginationControls = {
  pageCursors: [null],
  pageIndex: 0,
  isLoadingNext: false,
  hasNext: false,
};

type PaginationControlsReducer = [PaginationControls, React.Dispatch<Action>];

const paginationControlsReducer = (
  state: PaginationControls,
  action: Action
): PaginationControls => {
  const { pageIndex, pageCursors } = state;
  const isLastCursor = pageIndex === pageCursors.length - 1;
  const previousPageIndex = Math.max(0, pageIndex - 1);

  switch (action.type) {
    case PaginationControlsActionType.LOAD_NEXT:
      return {
        ...state,
        pageIndex: pageIndex + 1,
        isLoadingNext: true,
        // Only append cursors if we're loading a new page. Otherwise we know
        // we already have the cursor for this page and can safely ignore
        // whatever current cursor is passed in. This is necessary because as
        // we page data using our own state rather than relay's, the current
        // cursor is going to represent the page relay thinks it's on, not
        // necessarily the page we've locally generated.
        pageCursors: [...pageCursors, ...(isLastCursor ? [action.currentCursor] : [])],
        hasNext: pageIndex + 1 < pageCursors.length - 1,
      };
    case PaginationControlsActionType.LOAD_PREVIOUS:
      return {
        ...state,
        pageIndex: previousPageIndex,
        hasNext: previousPageIndex < pageCursors.length - 1,
      };
    case PaginationControlsActionType.LOADED_NEXT:
      return {
        ...state,
        isLoadingNext: false,
      };
    case PaginationControlsActionType.RESET:
      return initialState;
    default:
      return state;
  }
};

function createPaginationControls() {
  const PaginationControlsContext = createContext<PaginationControlsReducer | null>(null);

  const usePaginationControls = () => {
    const value = useContext(PaginationControlsContext);
    if (value === null) {
      throw new Error(
        'usePaginationControls is not a child of the matching PaginationControlsContext.Provider'
      );
    }

    return value;
  };

  const PaginationControlsProvider = ({
    fetchKey,
    children,
  }: React.PropsWithChildren<{ fetchKey: number }>) => {
    const reducerControls = useReducer<React.Reducer<PaginationControls, Action>>(
      paginationControlsReducer,
      initialState
    );
    const [, dispatch] = reducerControls;

    useEffect(() => {
      dispatch({
        type: PaginationControlsActionType.RESET,
      });
    }, [fetchKey, dispatch]);

    return (
      <PaginationControlsContext.Provider value={reducerControls}>
        {children}
      </PaginationControlsContext.Provider>
    );
  };

  return [usePaginationControls, PaginationControlsProvider] as const;
}

export { PaginationControlsActionType, createPaginationControls };
