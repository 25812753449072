import {
  mswGraphql,
  createConnection,
  parseMSWHeaders,
  MSW_DELAY_HEADER,
  createNode,
} from '@attentive/mock-data';

type QueryHandler = Parameters<typeof mswGraphql.query>[1];

const createCompanyData = (id: string | number) => ({
  id: `gql-${id}`,
  name: `Company ${id}`,
});

const COMPANY_LIST = Array.from({ length: 210 }, (_, n) => n).map(createCompanyData);

export interface ConnectedSelectQueryMSWHeaders {
  [MSW_DELAY_HEADER]?: number;
}

const paginationHandler: QueryHandler = async (req, res, ctx) => {
  const mswHeaders = parseMSWHeaders<ConnectedSelectQueryMSWHeaders>(req.headers);
  const { after = '', pageSize, filter, selectedCompany } = req.variables;

  const selectedCompanyId = selectedCompany ? selectedCompany.split('-')[1] : null;
  const companyNodes = COMPANY_LIST.filter((c) =>
    c.name.toLowerCase().includes(filter.searchTerm.toLowerCase() || '')
  );
  const [, start] = after.split('-');
  const startPage =
    (companyNodes.findIndex((c) => c.id === createCompanyData(start).id) + 1) / pageSize;

  const response = {
    companies: createConnection('Company', companyNodes, { page: startPage, limit: pageSize }),
    selectedCompany: createNode('Company', createCompanyData(selectedCompanyId)),
  };

  return res(ctx.delay(Number(mswHeaders[MSW_DELAY_HEADER]) ?? 0), ctx.data(response));
};

export const connectedSelectQueryMock = mswGraphql.query(
  'ConnectedSelect_Query',
  paginationHandler
);

export const connectedSelectRefetchableMock = mswGraphql.query(
  'ConnectedSelect_Companies_refetchable',
  paginationHandler
);
