import {
  connectedMultiSelectQueryMock,
  connectedMultiSelectRefetchableMock,
} from './ConnectedMultiSelect.mock';

const mocks = [connectedMultiSelectQueryMock, connectedMultiSelectRefetchableMock];

export * from './create-connected-multi-select';

export { mocks };
